/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

.modal-input {
  width: 100% !important;
  height: calc(1.5em + 1rem + 2px) !important;
}

.terms-content {
  height: 28rem;
}

@media (min-height: 720px) {
  .terms-content {
    height: 30rem;
  }
}

@tailwind utilities;
